import { useEffect, useRef, useState } from "react";
import { Container, Form, Navbar, Nav, Dropdown, Image } from "react-bootstrap";
import { Globe } from "@phosphor-icons/react";
import { FiChevronDown } from "react-icons/fi";
import style from "./header.module.css";
import logoImage from "./logo side transparent.svg";
import { useI18nContext } from "../context/I18nContext";
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import Hamburger from "hamburger-react";

function NavScrollExample() {
  const [activeLink, setActiveLink] = useState("");
  const [showNavbar, setShowNavbar] = useState(true);
  const { t, changeLanguage, language } = useI18nContext();
  const handleNavClick = (hash) => setActiveLink(hash);
  const [isOpen, setOpen] = useState(false);
  const toggleRef = useRef(null);
  const collapseRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (toggleRef.current && window.scrollY > 0) {
        setOpen(false);
        toggleRef.current.click();
      }
    };
    const handleClickOutside = (event) => {
      if (
        toggleRef.current &&
        collapseRef.current &&
        !collapseRef.current.contains(event.target) &&
        !toggleRef.current.contains(event.target)
      ) {
        setOpen(false);
        toggleRef.current.click();
      }
    };

    if (isOpen) {
      window.addEventListener('scroll', handleScroll);
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  return (
    <Navbar
      // dir={language === "ar" ? "rtl" : "ltr"}
      expand="lg"
      className={`${showNavbar ? "d-flex" : "d-flex"}`}
      style={{
        display: showNavbar ? "flex" : "flex",
        transition: "opacity 0.5s",
      }}
    >
      <Container fluid className="d-flex justify-center ">
        <div
          className="flex justify-between items-center w-full "
          // dir={language === "ar" ? "rtl" : "ltr"}
        >
          <Image
            src={logoImage}
            alt="logo Image"
            className={`me-0  object-cover h-[60px] w-[300px]  max-mob1:w-[190px] max-mob2:w-[180px] max-mob3:w-[160px]  `}
            priority='true'
            // loading="lazy"
          />
          <Form
            className={`z-50 d-flex absolute w-min ${language === "ar"
              // ? "left-10 max-lg:left-20 max-md:left-20"
              ? "right-10 max-lg:right-20 max-md:right-20 max-sm:right-20  "
              : "right-10 max-lg:right-20 max-md:right-20 max-sm:right-20  "
              }`}
          >
            <Dropdown >
              <Dropdown.Toggle
                className={`${style.noAfter} max-lg:w-fit`}
                variant=""
                id=""
              >
                <span className="d-flex text-gray-100 capitalize ">
                  <Globe className="h-6 w-10 max-lg:w-fit" aria-hidden="true" />{" "}
                  {language}
                  <FiChevronDown
                    className={`h-6 w-10 max-lg:w-fit `}
                    aria-hidden="true"
                  />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={``}>
                <Dropdown.Item
                  className={`text-center hover:text-black ${style.dropdownItem} `}
                  onClick={() => changeLanguage("en")}
                >
                  {t("Header.English")}
                </Dropdown.Item>
                <Dropdown.Item
                  className={`text-center hover:text-black ${style.dropdownItem} `}
                  onClick={() => changeLanguage("ar")}
                >
                  {t("Header.Arabic")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form>
          <Navbar.Toggle aria-label="Toggle navigation menu"   aria-controls="navbarScroll" className={`z-50 ${style.Hamburger} p-0 m-0 flex items-center justify-center `} ref={toggleRef}    >
            <Hamburger aria-label="Toggle menu" toggled={isOpen} toggle={setOpen} color="#fff" size={24}  />
          </Navbar.Toggle>
        <Navbar.Collapse
          ref={collapseRef}
          id="navbarScroll"
          className={`  px-6 z-40 ${style.bgBl}`}
        >
          <Nav className=" my-lg-0 text-lg" navbarScroll >
            <Link
              to="/"
              className={`nav-link   ${style.toggleLink} max-lg:my-2  ${activeLink === "/" ? style.activeLink : style.activeLink2
                }`}
              onClick={() => handleNavClick("/")}
            >
              {t(`Header.Home`)}
            </Link>
            <Link
              to="/services"
              aria-label="Go to services page"
              className={`nav-link ${style.toggleLink} max-lg:my-2   ${activeLink === "/services" ? style.activeLink : ""
                }`}
              onClick={() => handleNavClick("/services")}
            >
              {t("Header.Services")}
            </Link>
            <Link
              to="/projects"
              className={`nav-link ${style.toggleLink} max-lg:my-2   ${activeLink === "/projects" ? style.activeLink : ""
                }`}
              onClick={() => handleNavClick("/projects")}
            >
              {t("Header.Projects")}
            </Link>
            <Link
              to="/#contact"
              className={`nav-link ${style.toggleLink} max-lg:my-2  ${activeLink === "#contact" ? style.activeLink : ""
                }`}
              onClick={() => handleNavClick("#contact")}
            >
              {t("Header.ContactUs")}
            </Link>
          </Nav>
        </Navbar.Collapse>
        </div>

      </Container>
    </Navbar>
  );
}

export default NavScrollExample;

import style from "../servicesSlider/services.module.css";
import Slider2 from "./teamSlider";
import { useI18nContext } from "../context/I18nContext";

const Team = () => {
  const { t, language } = useI18nContext();

  return (
    <div className={style.content} id="team">
      <div className="relative flex mb-3 mt-3 items-center justify-center">
        <h2 className="stroke-1 display-3 px-3 mb-3 rounded-5 text-gray-100 bg-[--button]">
          {t("Team.Title")}
        </h2>
      </div>
      <Slider2 />
    </div>
  );
};

export default Team;

import styleText from "./services.module.css";
import Slider from "./Slider";
import { useI18nContext } from "../context/I18nContext";

const Services = () => {
  const { t, language } = useI18nContext();

  return (
    <div className={styleText.content} id="services">
      <div className="position-relative d-flex mb-3 mt-3 align-items-center justify-content-center ">
        <h2 className="stroke-1 display-1 text-center inline px-3 mb-3 rounded-5 text-gray-100 bg-[--button]">
          {t("Services.ourServices")}
        </h2>
      </div>
      <Slider />
    </div>
  );
};

export default Services;

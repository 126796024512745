import React, { useEffect, useState, useCallback } from "react";
import styles from "./styles.module.css";
import { useI18nContext } from "../context/I18nContext";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import Loading from "../Loading";
import { Link } from "react-router-dom";
import axios from "axios";
import Footer from "../footer/Footer";
import NavScrollExample from "../header/Header";
import { Helmet } from "react-helmet-async";

const Content = () => {
  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { t, language } = useI18nContext();
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCategories = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://dramsiteapi-4woj.onrender.com/api/v1/categories",
        {
          withCredentials: true,
          headers: { "X-API-KEY": "k2017secret" }
        }
      );
      const result = response.data;
      if (result && result.data) {
        setCategories(result.data);
      } else {
        // throw new Error("Invalid response structure");
        setError("Failed to load slides check your network");
      }
    } catch (error) {
      // console.error("Error fetching categories:", error);
      setError(error.message);
    }
  }, []);

  const fetchProjects = useCallback(async () => {
    try {
      setLoading(true);
      const typeFilter = selectedCategory ? `category=${selectedCategory}` : "";
      const query = `page=${pagination.currentPage}&limit=10${typeFilter ? `&${typeFilter}` : ""
        }`;
      const response = await axios.get(
        `https://dramsiteapi-4woj.onrender.com/api/v1/projects?${query}`,
        {
          withCredentials: true,
          headers: { "X-API-KEY": "k2017secret" }
        }
      );
      const result = response.data;
      if (result && result.data) {
        setProjects(result.data);
        setPagination((prev) => ({
          ...prev,
          totalPages: result.pagination.numberOfPages || 1,
        }));
      } else {
        // throw new Error("Invalid response structure");
        setError("Failed to load slides check your network");
      }
    } catch (error) {
      // console.error("Error fetching projects:", error);
      setError("Failed to load check your network");
    } finally {
      setLoading(false);
    }
  }, [selectedCategory, pagination.currentPage]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const handleCategoryChange = (id) => {
    setSelectedCategory(id);
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
    }));
  };

  const handlePageChange = (newPage) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      currentPage: newPage,
    }));
  };

  const pageButtons = Array.from(
    { length: pagination.totalPages },
    (_, index) => index + 1
  );

  return (
    <>
      <Helmet>
        <title>DRAM Code - Projects</title>
        <link rel="canonical" href="https://dramsiteapi-4woj.onrender.com/projects" />
        <meta
          name="description"
          content="Explore our diverse portfolio of successful projects, showcasing our expertise in delivering high-quality software solutions that drive business growth."
        />
      </Helmet>
      <div>
        <div className={`bg-[#011b3d] `} id="projects">
          <NavScrollExample />
        </div>
        {error ? (
          <div
            class="alert alert-danger d-flex align-items-center my-4 w-[80%] m-auto"
            role="alert"
          >
            <div>{error}</div>
          </div>
        ) : (
          <div dir={language === "ar" ? "rtl" : "ltr"}>
            <div className="m-4">
              <h1 className="font-bold text-xl my-4  max-mob:text-center">
                {t("Projects.ProjectsWeDeveloped")}
              </h1>
              <form className="flex max-mob:justify-center my-4 mx-2">
                {categories.length > 0 && (
                  <div
                    className={` ${selectedCategory === null ? "text-blue-500" : ""
                      }`}
                  >
                    <input
                      type="radio"
                      id="all"
                      name="category"
                      checked={selectedCategory === null}
                      value="all"
                      onChange={() => handleCategoryChange(null)}
                      className={`${language === "ar" ? "ml-3" : "mr-3"}`}
                      aria-label="All categories"
                    />
                    <label htmlFor="all">{t("Projects.All")}</label>
                  </div>
                )}
                {categories.length > 0
                  ? categories.map((category) => (
                    <div
                      key={category._id}
                      className={` ${language === "ar" ? "mr-3" : "ml-3"} ${selectedCategory === category._id
                        ? "text-blue-500"
                        : ""
                        }`}
                    >
                      <input
                        type="radio"
                        id={`category-${category._id}`}
                        name="category"
                        value={category._id}
                        checked={selectedCategory === category._id}
                        onChange={() => handleCategoryChange(category._id)}
                        className={`${language === "ar" ? "ml-2" : "mr-2"}`}
                        aria-label={category.name_en}
                      />
                      <label htmlFor={`category-${category._id}`}>
                        {language === "ar"
                          ? category.name_ar
                          : category.name_en}
                      </label>
                    </div>
                  ))
                  : ""}
              </form>
              {loading ? (
                <div className="flex justify-center items-center">
                  <Loading />
                </div>
              ) : projects.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-mob:flex max-mob:flex-col max-mob:items-center max-mob:justify-center max-mob:my-3 ">
                  {projects.map((project) => (
                    <div key={project._id} className={`${styles.card}`}>
                      <Link to={`/projects/${project._id}`}>
                        <div
                          className={`w-100  max-mob1:w-52  ${styles.imageContainer}`}
                        >
                          <img
                            className={`rounded-3 w-96 h-40 object-fill ${styles.image}`}
                            src={project.cover}
                            alt={`Project : ${project.name}`}
                          />
                          <div className={styles.overlay}>
                            <p className={styles.text}>
                              {language === "ar"
                                ? project.name_ar
                                : project.name_en}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <p>{t("Projects.NoProjects")}</p>
              )}

              <nav className="flex flex-col md:flex-row  justify-center p-4 gap-8 max-md:items-center max-md:gap-0">
                <ul
                  className="inline-flex items-stretch w-fit rounded-full max-md:ml-0 max-md:pl-0"
                  dir="ltr"
                >
                  <li className="bg-slate-300 opacity-2 rounded-l-full">
                    <button
                      className="flex items-center justify-center h-full py-1 px-1 text-blue-600 rounded-l-full border border-gray-300 hover:bg-gray-100 hover:text-blue-700"
                      onClick={() =>
                        handlePageChange(pagination.currentPage - 1)
                      }
                      disabled={pagination.currentPage === 1}
                    >
                      <CaretLeft size={20} weight="bold" />
                    </button>
                  </li>
                  {pageButtons.map((page) => (
                    <li key={page} className="bg-slate-300 opacity-2">
                      <button
                        className={`flex items-center justify-center text-sm py-1 mx-2 my-1 px-2 leading-tight ${pagination.currentPage === page
                          ? "bg-blue-500 rounded-full text-white"
                          : "text-white hover:bg-gray-200 hover:text-blue-500 hover:rounded-full"
                          }`}
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </button>
                    </li>
                  ))}
                  <li className="bg-slate-300 opacity-2 rounded-r-full">
                    <button
                      className="flex items-center justify-center h-full py-1 px-1 ml-0 text-blue-600 rounded-r-full border border-gray-300 hover:bg-gray-100 hover:text-blue-700-700"
                      onClick={() =>
                        handlePageChange(pagination.currentPage + 1)
                      }
                      disabled={
                        pagination.currentPage === pagination.totalPages
                      }
                    >
                      <CaretRight size={20} weight="bold" />
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}

        <Footer />
      </div>
    </>
  );
};
export default Content;

import ContactForm from "./contact/contact";
import DevelopmentProcess from "./develop/Develop";
import Footer from "./footer/Footer";
import NavScrollExample from "./header/Header";
import HeroSection from "./hero/Hero";
import Project from "./projectsHome/project";
import Services from "./servicesSlider/services";
import Team from "./teamSlier/team";
import style from "./header/header.module.css";
import { Helmet } from "react-helmet-async";

function Home() {
  return (
    <div>
      <Helmet>
        <title>DRAM Code - Software Company</title>
        <link rel="canonical" href="https://dramcode.top/" />
        <meta
          name="description"
          content="We implement the corporate performance software to generate outstanding software products to take your business to the next level."
        />
      </Helmet>
      <div className={style.content}>
        <NavScrollExample />
        <HeroSection />
      </div>
      <DevelopmentProcess />
      <Services />
      <Project />
      <Team />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default Home;

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home.jsx';
import Services from './components/services/services.jsx';
import ServicesComponent from './components/services/one/service.jsx';
import ProjectPage from './components/projects/one/project.jsx';
import Content from './components/projects/content.jsx';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/service/:id" element={<ServicesComponent />} />
        <Route path="/projects" element={<Content />} />
        <Route path="/projects/:id" element={<ProjectPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import style from "./project.module.css";
import { useI18nContext } from "../context/I18nContext";
import Slider from "./sliderProject";

const Project = () => {
  const { t } = useI18nContext();

  return (
    <>
      <div className={`mx-auto ${style.content}`} id="projects">
        <div className="position-relative d-flex mb-3 mt-3 align-items-center justify-content-center">
          <h2 className="stroke-1 display-3 px-3 mb-3 rounded-5 text-gray-100 bg-[--button]">
            {t("Project.Title")}
          </h2>
        </div>
        <Slider />
      </div>
    </>
  );
};

export default Project;

import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Link as ScrollLink } from "react-scroll";
import { useI18nContext } from "../context/I18nContext";
import SocialIcons from "../accounts/Account";
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection = () => {
  const { t, language } = useI18nContext();
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <section
      className={`z-10 section  absolute top-40 max-tab:top-40 max-mob:top-40 max-mob1:top-28 max-mob3:top-24  ${
        language === "ar"
          ? "right-5  max-md:right-0 max-sm:right-7"
          : "left-5 max-md:left-0 max-sm:left-7"
      }`}
      dir={language === "ar" ? "rtl" : "ltr"}
    >
      <div className=" grid grid-cols-4 sm:grid-cols-12 text-white z-10">
        {<SocialIcons />}

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="col-span-8 lg:mx-32 content place-self-center text-start sm:text-left"
        >
          <h1
            data-aos="zoom-in-up"
            className={`lineAround  relative  block text-white text-5xl${
              language === "ar"
                ? " text-right right-32 max-lg:right-16 max-md:right-10"
                : "text-left left-32 max-lg:left-16 max-md:left-10"
            } text-white text-2xl sm:text-5xl lg:text-3xl lg:leading-normal font-extrabold`}
          >
            <span className=" lineAround1 lg:text-6xl lg:leading-normal d-block font-extrabold">
              {t("HeroSection.title.we")}
            </span>
            <span className="implement lg:text-6xl lg:leading-normal d-block font-extrabold">
              {t("HeroSection.title.implement")}
            </span>
            <span className="lineAround2  lg:text-5xl  lg:leading-normal d-block font-extrabold">
              {t("HeroSection.title.corporateSoftware")}
            </span>
          </h1>
          <p
            data-aos={language === "ar" ? "fade-right" : "fade-left"}
            className={`relative w-80 max-mob3:w-64 max-md:mt-5 text-[#fff] text-base sm:text-lg mb-6 lg:text-xl ${
              language === "ar"
                ? "text-right me-5 right-60 max-lg:right-32 max-md:right-0 max-sm:-right-7"
                : "text-left ms-5 left-60 max-lg:left-32 max-md:left-0 max-sm:-left-7"
            }`}
          >
            {t("HeroSection.description")}
          </p>

          <div className="flex lg:ms-16">
            <ScrollLink
              data-aos={language === "ar" ? "fade-left" : "fade-right"}
              to="contact"
              smooth={true}
              duration={800}
              aria-label={t("HeroSection.contactUs")}
              className={`text-decoration-none cursor-pointer flex relative mx-5 z-10 px-8 py-3 sm:w-fit rounded-full bg-blue-500 text-white hover:tracking-widest 
              ${
                language === "ar"
                  ? "right-96 max-lg:right-32 max-md:right-0 max-sm:-right-7"
                  : "left-96 max-lg:left-32 max-md:left-0 max-sm:-left-7"
              }`}
            >
              <span className="text-white">{t("HeroSection.contactUs")}</span>
              {language === "ar" ? (
                <FiChevronLeft className="h-6 w-10" aria-hidden="true" />
              ) : (
                <FiChevronRight className="h-6 w-10" aria-hidden="true" />
              )}
            </ScrollLink>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default HeroSection;

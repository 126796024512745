import FooterLogo from "./footer.png";
import { FiMail } from "react-icons/fi";
import { FaLinkedinIn, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import twitter from "./download (3).png";
import { useI18nContext } from "../context/I18nContext";
import { HashLink as Link } from "react-router-hash-link";
import { Image } from "react-bootstrap";

function Footer() {
  const { t } = useI18nContext();
  return (
    <footer className="z-30 bg-[#011b3d] p-4 w-[100%] m-auto flex items-center justify-center relative">
      <div className=" w-[90%] py-6 pb-0 lg:py-8 max-tab:ms-0 max-tab:mx-4 max-md:mx-6 max-md:pt-0">
        <div className="flex justify-between max-tab:flex-wrap max-tab:gap-4   ">
          <div className="w-1/4 max-tab:w-52 max-md:w-64 max-mob1:w-[21rem]">
            <Link to="/#contact" className="flex items-center">
              <Image
                src={FooterLogo}
                className="w-full h-auto max-w-max"
                alt="FlowBite Logo"
                // loading="lazy"
              />
            </Link>
          </div>

          <div className="w-1/4 text-gray-400 font-medium max-tab:w-max">
            <ul className="text-gray-500 dark:text-gray-400">
              <li className="mb-4">
                <Link
                  aria-label="Go to services section"
                  to="/#services"
                  className="hover:underline text-xl cursor-pointer"
                >
                  {t("Footer.services")}
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="/#projects"
                  className="hover:underline text-xl cursor-pointer"
                >
                  {t("Footer.projects")}
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="/#team"
                  className="hover:underline text-xl cursor-pointer"
                >
                  {t("Footer.team")}
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="/#contact"
                  className="hover:underline text-xl cursor-pointer"
                >
                  {t("Footer.contactUs")}
                </Link>
              </li>
            </ul>
          </div>

          <div className="w-1/4 text-gray-400 max-tab:w-max">
            <h2 className="mb-6 text-2xl font-medium capitalize dark:text-white">
              {t("Footer.services")}
            </h2>
            <ul className="text-gray-500 dark:text-gray-400">
              <li className="mb-4">
                <Link
                  aria-label="Go to businessAnalysis"
                  to="/#services"
                  className="hover:underline text-xl"
                >
                  {t("Footer.businessAnalysis")}
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  aria-label="Go to webDevelopment"
                  to="/#services"
                  className="hover:underline text-xl"
                >
                  {t("Footer.webDevelopment")}
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  aria-label="Go to mobileDevelopment"
                  to="/#services"
                  className="hover:underline text-xl"
                >
                  {t("Footer.mobileDevelopment")}
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  aria-label="Go to ai"
                  to="/#services"
                  className="hover:underline text-xl"
                >
                  {t("Footer.ai")}
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  aria-label="Go to uiUxDesign"
                  to="/#services"
                  className="hover:underline text-xl"
                >
                  {t("Footer.uiUxDesign")}
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  aria-label="Go to graphicDesign"
                  to="/#services"
                  className="hover:underline text-xl"
                >
                  {t("Footer.graphicDesign")}
                </Link>
              </li>
            </ul>
          </div>

          <div className="w-1/4 text-gray-400 max-tab:w-max">
            <h2 className="mb-6 text-2xl  font-medium capitalize dark:text-white">
              {t("Footer.contactTitle")}
            </h2>
            <ul className="text-gray-500 dark:text-gray-400">
              <li className="mb-4">
                <div className="flex flex-col">
                  <div
                    className="flex items-center mb-4 min-w-max"
                    title="What's"
                  >
                    <FaWhatsapp className="w-5 h-5 me-2" />
                    <Link
                      to="https://wa.me/201091548180"
                      target="_blank"
                      className="pointer"
                      dir="ltr"
                    >
                      {t("Footer.phone")}
                    </Link>
                  </div>
                  <div
                    className="flex items-center mb-4 min-w-max"
                    title="Email"
                  >
                    <FiMail className="w-5 h-5 me-2" />
                    <Link
                      to="mailto:info@dramcode.top"
                      target="_blank"
                      className="pointer"
                    >
                      {t("Footer.email")}
                    </Link>
                  </div>
                  <div className="flex items-center text-white gap-3 text-3xl">
                    <Link
                     aria-label="facebook page"
                      to="https://www.facebook.com/profile.php?id=61552302150847"
                      target="_blank"
                    >
                      <FiFacebook />
                    </Link>
                    <Link
                    aria-label="Instagram profile"
                      to="https://www.facebook.com/profile.php?id=61552302150847"
                      target="_blank"
                    >
                      <FaInstagram />
                    </Link>
                    <Link
                    aria-label="x profile"
                      to="https://www.facebook.com/profile.php?id=61552302150847"
                      target="_blank"
                    >
                      <Image
                        src={twitter}
                        alt="twitter"
                        width={24}
                        height={24}
                      />
                    </Link>
                    <Link
                      aria-label="linkedin"
                      to="https://www.facebook.com/profile.php?id=61552302150847"
                      target="_blank"
                    >
                      <FaLinkedinIn />
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="w-full h-0.5 bg-gray-200 my-4 "></div>

        <div className="text-center text-white">
          <p className="mb-0 pb-0">© DRAM Code 2024</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import { useState, useEffect, useCallback } from "react";
import styles from "../servicesSlider/services.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { useMediaQuery } from "react-responsive";
import { useI18nContext } from "../context/I18nContext";
import Loading from "../Loading";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import axios from "axios";

const Slider = () => {
  const [slides, setSlides] = useState([]);
  const { language } = useI18nContext();
  const isLgScreen = useMediaQuery({ query: "(min-width: 1024px)" });
  const isTabScreen = useMediaQuery({ query: "(min-width: 765px)" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  const fetchSlides = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://dramsiteapi-4woj.onrender.com/api/v1/projects",
        {
          withCredentials: true,
          headers: { "X-API-KEY": "k2017secret" }
        }
      );
      const data = response.data;
      const slidesArray = data.data;
      if (Array.isArray(slidesArray)) {
        const formattedSlides = slidesArray.map((slide) => ({
          id: slide._id,
          name:
            language === "ar" ? slide.category.name_ar : slide.category.name_en,
          name2: language === "ar" ? slide.name_ar : slide.name_en,
          cover: slide.cover,
        }));
        setLoading(false);
        setSlides(formattedSlides.slice(0, 20));
      } else {
        setLoading(false);
        setError("Failed to load check your network");
      }
    } catch (error) {
      setLoading(false);
      setError("Failed to load check your network");
    }
  }, [language]);

  useEffect(() => {
    fetchSlides();
  }, [fetchSlides]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return (
      <div
        class="alert alert-danger d-flex justify-center align-items-center"
        role="alert"
      >
        {error}
      </div>
    );
  }
  return (
    <div
      className={`${styles.slider} ${styles.slider2}`}
      dir={language === "ar" ? "rtl" : "ltr"}
    >
      <Swiper
        slidesPerView={isLgScreen ? 3 : isTabScreen ? 2 : 1}
        spaceBetween={30}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        modules={[Pagination, Autoplay]}
        className={styles.swiper}
        data-aos="fade-up"
      >
        {slides.map((slide, index) => (
          <SwiperSlide className={styles.swiperSlide} key={slide.id}>
            <Link
              to={`/projects/${slide.id}`}
              aria-label={`View details for ${slide.name}`}
            >
              <div className={styles.card}>
                <div className={styles.imageContainer}>
                  <img
                    data-aos="zoom-in"
                    className={`rounded-3 ${styles.image}`}
                    src={slide.cover}
                    alt={slide.name}
                  />
                  <div className={styles.overlay}>
                    <p className={styles.text} data-aos="fade-right">
                      {slide.name}
                    </p>
                    <p className={styles.text} data-aos="fade-left">
                      {slide.name2}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
